import { ADDITIONAL_WAIT_TIME } from '@/utils/CONVERSATION_CONSTANTS.ts'
import { useEffect, useState } from 'react'
import { selectUser } from '../../store/auth/authSlice.ts'
import { useAppSelector } from '../../store/hooks.ts'
import { useConversationPageHook } from '../conversations/[conversationId]/use-conversation-page.hook.tsx'
import { useNavigate } from 'react-router-dom'
import { LOCAL_STORAGE_REDIRECTION_KEY } from '@/ROUTE_PATHS.ts'

export function useGreetingUserHook() {
  const [name, setName] = useState('')
  const currentUser = useAppSelector(selectUser)
  const { navigateToFirstConversationOrCreateOneIfNoneExists } = useConversationPageHook()
  const navigate = useNavigate()

  useEffect(() => {
    setName(currentUser?.firstname ?? '')
    async function redirect() {
      const redirect = localStorage.getItem(LOCAL_STORAGE_REDIRECTION_KEY)
      if (redirect) {
        navigate(`/legal-watch`)
        localStorage.removeItem(LOCAL_STORAGE_REDIRECTION_KEY)
      } else {
        await navigateToFirstConversationOrCreateOneIfNoneExists()
      }
    }
    const timeout = setTimeout(redirect, ADDITIONAL_WAIT_TIME)
    return () => {
      clearTimeout(timeout)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const capitalizeName = (): string => {
    if (!name) return ''
    return name[0].toUpperCase() + name.slice(1)
  }

  return { capitalizedName: capitalizeName() }
}
